import React from 'react'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Prices.css'

export default function Prices() {
  return (
    <div className='container'>
        <h1 className="section-title-price">Les forfaits dépannage</h1>
        <div className='card-container'>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Diagnostic panne</p>
                        <div className="card-price">49,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)" >Diagnostic complet de la panne<br/><br/>
                            Devis de réparation effectué sur place dans la mesure du possible<br/><br/>
                            En cas d'accord d'un devis (réparation ou remplacement) : 35 € au lieu de 49.99 €
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Sauvegarde, transfert de données</p>
                        <div className="card-price">49,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Sauvegarde des fichiers vers un disque dur externe<br/><br/>
                            Transfert des fichiers d'un disque dur externe vers un ordinateur<br/><br/>
                            Supplément de 10 € facturé par tranche de 30 Go au dessus de 100 Go
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Nettoyage et optimisation système d'exploitation</p>
                        <div className="card-price">59,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Vérification du système<br/><br/>
                            Désinstallation des logiciels superflus<br/><br/>
                            Nettoyage complet du disque dur<br/><br/>
                            Mise à jour du système d'exploitation
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Installation, paramétrage d'un nouvel ordinateur</p>
                        <div className="card-price">59,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Installation et/ou mise à jour des pilotes des périphériques et applications<br/><br/>
                            Paramétrage personnalisé du système d'exploitation<br/><br/>
                            Transfert de fichiers vers le nouveau PC
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Installation de logiciels et périphériques <sup>*</sup></p>
                        <div className="card-price">59,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Connexion, installation et mise à jour des périphériques et logiciels. Pas de logiciels piratés.<br/><br/>
                            Ajout, remplacement du matériel si besoin
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Remplacement de pièces <sup>*</sup></p>
                        <div className="card-price">69,99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Remplacement de composants internes et périphériques<br/><br/>  
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Clonage disque dur vers stockage</p>
                        <div className="card-price">74.99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Sécurisation des données vers le support externe avant clonage<br/><br/>
                            Copie du disque dur vers le nouveau support de stockage HDD ou SSD<br/><br/>
                            Remplacement de l'ancien support par le nouveau<br/><br/>
                            Test final
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Migration, mises à jour Windows <sup>*</sup></p>
                        <div className="card-price">74.99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Sauvegarde des données personnelles<br/><br/>
                            Mise à jour de l'ancien système d'exploitation et nettoyage du disque dur avec défragmentation<br/><br/>
                            Migration vers la nouvelle version de Windows et paramétrage
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Réinstallation complète du PC <sup>*</sup></p>
                        <div className="card-price">104.99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Enlèvement de votre PC à domicile<br/><br/>
                            Sauvegarde de vos données personnelles<br/><br/>
                            Retour de votre matériel chez vous avec le branchement complet des périphériques connectés                            
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Prestations diverses</p>
                        <div className="card-price">49,99 € / heure</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Toute prestation de dépannage basique qui ne rentre pas dans les forfaits</a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-front">
                        <p>Option nettoyage, dépoussiérage</p>
                        <div className="card-price">24.99 €</div>
                        <FontAwesomeIcon className="arronw-icon" icon={faArrowRight} />
                    </div>
                    <div className="card-back">
                        <a href="javascript:void(0)">Nettoyage d'écran, clavier, souris et boîtier<br/><br/>
                            Dépoussiérage interne PC fixes et portables<br/><br/>
                            Proposé uniquement en accompagnement d'un forfait cité précédemment
                        </a>
                    </div>
                </div>
                <a className='contact-link' href="/contact">Contactez-nous !</a>
            </div>
        </div>
        <p className="note-prices">Les frais kilométriques s'ajoutent aux tarifs ci-dessus à hauteur de 0.95€/km.
            Toute heure entamée est dûe. Toute intervention après 20h, dimanche et jours fériés donneront lieu à un supplément de 20% du tarif d'intervention. 
            Les tarifs sont exprimés en prix net à payer (TVA non applicable, article 293B du C.G.I.). 
            Modes de paiements acceptés : virement, chèques et espèces.<br/><br/>
            * Le tarif ne comprend pas le prix des composants ou logiciels à installer et le client doit être en mesure de fournir la clé de licence Windows et logiciels.
        </p>
    </div>
  )
}